import dayjs from "dayjs";
import {
  BarrackType,
  BodyPart,
  BombardType,
  CampType,
  MongenRace,
  MongenStatType,
  PlotType,
  Rarity,
  Skill,
  SkillType,
  TowerType,
  TrapType,
} from "interfaces";
import GameConstant from "interfaces/game-constants";

function formatHostUrl(hostapi: string) {
  if (hostapi[hostapi.length - 1] === "/") {
    return hostapi.slice(0, -1);
  }
  return hostapi;
}
export const toHex = (num: any): string => {
  const val = Number(num);
  return "0x" + val.toString(16);
};
function getRarityIcon(rarity: Rarity) {
  const baseUrl = "/assets/images/rarity";
  switch (rarity) {
    case Rarity.Common:
      return baseUrl + "/common-icon.svg";
    case Rarity.Uncommon:
      return baseUrl + "/uncommon-icon.svg";
    case Rarity.Rare:
      return baseUrl + "/rare-icon.svg";
    case Rarity.Epic:
      return baseUrl + "/epic-icon.svg";
    case Rarity.Legendary:
      return baseUrl + "/legendary-icon.svg";
    default:
      return null;
  }
}

const getCountdownText = (date: any) => {
  if (!date) {
    return null;
  }
  let diff = dayjs(date).diff(dayjs(), "second");
  if (diff <= 0 || dayjs(date).isBefore(dayjs())) {
    return null;
  }
  if (dayjs(date).isBefore(dayjs())) {
    return {
      days: "0",
      hours: "0",
      minutes: "0",
      seconds: "0",
    };
  }
  let day = Math.floor(diff / 86400) + "";
  let hour = Math.floor((diff % 86400) / 3600) + "";
  let min = Math.floor((diff % 3600) / 60) + "";
  let second = (diff % 60) + "";
  return {
    days: day.padStart(2, "0"),
    hours: hour.padStart(2, "0"),
    minutes: min.padStart(2, "0"),
    seconds: second.padStart(2, "0"),
  };
};

const getCountdownDisplay = (date: any) => {
  if (!date) {
    return null;
  }
  let diff = dayjs(date).diff(dayjs(), "second");
  if (diff <= 0 || dayjs(date).isBefore(dayjs())) {
    return "Expired time";
  }
  if (dayjs(date).isBefore(dayjs())) {
    return "Expire time";
  }

  let hourV = Math.floor(diff / 3600);
  let minV = Math.floor((diff % 3600) / 60);
  let secondV = diff % 60;

  let hour = Math.floor((diff % 86400) / 3600) + "";
  let min = Math.floor((diff % 3600) / 60) + "";
  let second = (diff % 60) + "";

  let res = "";
  if (hourV > 0) res += `${hour.padStart(2, "0")}h `;
  if (minV > 0) res += `${min.padStart(2, "0")}m `;
  res += `${second.padStart(2, "0")}s`;

  return res;
};

function convertToInternationalCurrencySystem(labelValue: number, digits = 2) {
  if (!labelValue && labelValue != 0) return null;
  let value = Math.abs(Number(Number(labelValue).toFixed(13)));

  let digitValue = 100;
  switch (digits) {
    case 2:
      digitValue = 1.0e2;
      break;
    case 3:
      digitValue = 1.0e3;
      break;
    case 4:
      digitValue = 1.0e4;
      break;
    case 5:
      digitValue = 1.0e5;
      break;
    case 6:
      digitValue = 1.0e6;
      break;
    case 7:
      digitValue = 1.0e7;
      break;
    case 8:
      digitValue = 1.0e8;
      break;
  }

  if (value >= 1.0e9) {
    value = value / 1.0e9;
    value = Math.floor(Number(value * digitValue)) / digitValue;
    value = toFixed(value);
    return value + "B";
  } else if (value >= 1.0e6) {
    value = value / 1.0e6;
    value = Math.floor(Number(value * digitValue)) / digitValue;
    value = toFixed(value);
    return value + "M";
  } else if (value >= 1.0e3) {
    value = value / 1.0e3;
    value = Math.floor(Number(value * digitValue)) / digitValue;
    value = toFixed(value);
    return value + "K";
  } else {
    value = value / 1;
    value = Math.floor(Number(value * digitValue)) / digitValue;
    value = toFixed(value);
    return value;
  }

  function toFixed(x: any) {
    if (Math.abs(x) < 1.0) {
      var e = parseInt(x.toString().split("e-")[1]);
      if (e) {
        x *= Math.pow(10, e - 1);
        x = "0." + new Array(e).join("0") + x.toString().substring(2);
      }
    } else {
      var e = parseInt(x.toString().split("+")[1]);
      if (e > 20) {
        e -= 20;
        x /= Math.pow(10, e);
        x += new Array(e + 1).join("0");
      }
    }
    return x;
  }
  // // Nine Zeroes for Billions
  // return Math.abs(Number(labelValue)) >= 1.0e9
  //   ? (Math.abs(Number(labelValue)) / 1.0e9).toFixed(2) + "B"
  //   : // Six Zeroes for Millions
  //   Math.abs(Number(labelValue)) >= 1.0e6
  //   ? (Math.abs(Number(labelValue)) / 1.0e6).toFixed(2) + "M"
  //   : // Three Zeroes for Thousands
  //   Math.abs(Number(labelValue)) >= 1.0e3
  //   ? (Math.abs(Number(labelValue)) / 1.0e3).toFixed(2) + "K"
  //   : Math.abs(Number(labelValue)).toFixed(2);
}
function randomInt(min: number, max: number): number {
  return Math.round(min + Math.random() * (max - min));
}

function getMongenStat(
  dna: number[][],
  level: number,
  evolution: number,
  morale_eff_value = 1
) {
  const bodyPartsStat: number[][] = [
    GameConstant.BaseFormStat.map(
      (i: number[], statIndex: number) =>
        i[dna[BodyPart.Form][0]] *
        GameConstant.FormRarityBaseMultiply[statIndex][dna[BodyPart.Form][1]]
    ),
    GameConstant.BaseBodyPartStat.map(
      (i: number[], statIndex: number) =>
        i[dna[BodyPart.Head][0]] *
        GameConstant.BodyPartRarityBaseMultiply[statIndex][
          dna[BodyPart.Head][1]
        ]
    ),
    GameConstant.BaseBodyPartStat.map(
      (i: number[], statIndex: number) =>
        i[dna[BodyPart.Eyes][0]] *
        GameConstant.BodyPartRarityBaseMultiply[statIndex][
          dna[BodyPart.Eyes][1]
        ]
    ),
    GameConstant.BaseBodyPartStat.map(
      (i: number[], statIndex: number) =>
        i[dna[BodyPart.Horns][0]] *
        GameConstant.BodyPartRarityBaseMultiply[statIndex][
          dna[BodyPart.Horns][1]
        ]
    ),
    GameConstant.BaseBodyPartStat.map(
      (i: number[], statIndex: number) =>
        i[dna[BodyPart.Tail][0]] *
        GameConstant.BodyPartRarityBaseMultiply[statIndex][
          dna[BodyPart.Tail][1]
        ]
    ),
    GameConstant.BaseBodyPartStat.map(
      (i: number[], statIndex: number) =>
        i[dna[BodyPart.Back][0]] *
        GameConstant.BodyPartRarityBaseMultiply[statIndex][
          dna[BodyPart.Back][1]
        ]
    ),
  ];
  let baseStat = bodyPartsStat.reduce((a: number[], b: number[]) => {
    let tmp = [];
    for (var i = 0; i < b.length; i++) {
      tmp.push((a[i] || 0) + b[i]);
    }
    return tmp;
  }, []);
  //calculate max stat

  // baseStat = baseStat.map((i) => Math.round(i));
  let race = dna[BodyPart.Form][0];
  let currentStat: number[] = [
    //health
    baseStat[MongenStatType.Health] * 5 +
      150 +
      20 *
        baseStat[MongenStatType.Health] *
        (GameConstant.LevelStatMultiply[MongenStatType.Health][race] / 100) *
        (level + evolution - 2) *
        10,
    //sturdiness
    baseStat[MongenStatType.Sturdiness] *
      (1 +
        4 *
          (GameConstant.LevelStatMultiply[MongenStatType.Sturdiness][race] /
            100) *
          (level + evolution - 2)),
    //magic
    baseStat[MongenStatType.Magic] *
      (1 +
        6 *
          (GameConstant.LevelStatMultiply[MongenStatType.Magic][race] / 100) *
          (level + evolution - 2)),
    //aggresion
    baseStat[MongenStatType.Aggresion] *
      (1 +
        3 *
          (GameConstant.LevelStatMultiply[MongenStatType.Aggresion][race] /
            100) *
          (level + evolution - 2)),
  ];

  currentStat = currentStat.map((stat) => stat * morale_eff_value);
  baseStat = baseStat.map((i: number) => Math.round(i));
  currentStat = currentStat.map((i: number) => Math.round(i));
  return currentStat;
}

function getFullMongenStat(
  dna: number[][],
  level: number,
  evolution: number,
  morale_eff_value = 1
): {
  base: number[];
  max: number[];
  ultimateMax: number[];
  rarity: number;
  basePower: number;
  currentPower: number;
  maxPower: number;
} {
  const bodyPartsStat: number[][] = [
    GameConstant.BaseFormStat.map(
      (i: number[], statIndex: number) =>
        i[dna[BodyPart.Form][0]] *
        GameConstant.FormRarityBaseMultiply[statIndex][dna[BodyPart.Form][1]]
    ),
    GameConstant.BaseBodyPartStat.map(
      (i: number[], statIndex: number) =>
        i[dna[BodyPart.Head][0]] *
        GameConstant.BodyPartRarityBaseMultiply[statIndex][
          dna[BodyPart.Head][1]
        ]
    ),
    GameConstant.BaseBodyPartStat.map(
      (i: number[], statIndex: number) =>
        i[dna[BodyPart.Eyes][0]] *
        GameConstant.BodyPartRarityBaseMultiply[statIndex][
          dna[BodyPart.Eyes][1]
        ]
    ),
    GameConstant.BaseBodyPartStat.map(
      (i: number[], statIndex: number) =>
        i[dna[BodyPart.Horns][0]] *
        GameConstant.BodyPartRarityBaseMultiply[statIndex][
          dna[BodyPart.Horns][1]
        ]
    ),
    GameConstant.BaseBodyPartStat.map(
      (i: number[], statIndex: number) =>
        i[dna[BodyPart.Tail][0]] *
        GameConstant.BodyPartRarityBaseMultiply[statIndex][
          dna[BodyPart.Tail][1]
        ]
    ),
    GameConstant.BaseBodyPartStat.map(
      (i: number[], statIndex: number) =>
        i[dna[BodyPart.Back][0]] *
        GameConstant.BodyPartRarityBaseMultiply[statIndex][
          dna[BodyPart.Back][1]
        ]
    ),
  ];
  let baseStat = bodyPartsStat.reduce((a: number[], b: number[]) => {
    let tmp = [];
    for (var i = 0; i < b.length; i++) {
      tmp.push((a[i] || 0) + b[i]);
    }
    return tmp;
  }, []);
  //calculate max stat

  // baseStat = baseStat.map((i) => Math.round(i));
  let race = dna[BodyPart.Form][0];
  let currentStat: number[] = [
    //health
    baseStat[MongenStatType.Health] * 5 +
      150 +
      20 *
        baseStat[MongenStatType.Health] *
        (GameConstant.LevelStatMultiply[MongenStatType.Health][race] / 100) *
        (level + evolution - 2) *
        10,
    //sturdiness
    baseStat[MongenStatType.Sturdiness] *
      (1 +
        4 *
          (GameConstant.LevelStatMultiply[MongenStatType.Sturdiness][race] /
            100) *
          (level + evolution - 2)),
    //magic
    baseStat[MongenStatType.Magic] *
      (1 +
        6 *
          (GameConstant.LevelStatMultiply[MongenStatType.Magic][race] / 100) *
          (level + evolution - 2)),
    //aggresion
    baseStat[MongenStatType.Aggresion] *
      (1 +
        3 *
          (GameConstant.LevelStatMultiply[MongenStatType.Aggresion][race] /
            100) *
          (level + evolution - 2)),
  ];

  currentStat = currentStat.map((stat) => stat * morale_eff_value);

  let MAX_LEVEL = 100;
  let MAX_EVOL = 5;
  let ultimateMaxStat: number[] = [
    //health
    baseStat[MongenStatType.Health] * 5 +
      150 +
      20 *
        baseStat[MongenStatType.Health] *
        (GameConstant.LevelStatMultiply[MongenStatType.Health][race] / 100) *
        (MAX_LEVEL + MAX_EVOL - 2) *
        10,
    //sturdiness
    baseStat[MongenStatType.Sturdiness] *
      (1 +
        4 *
          (GameConstant.LevelStatMultiply[MongenStatType.Sturdiness][race] /
            100) *
          (MAX_LEVEL + MAX_EVOL - 2)),
    //magic
    baseStat[MongenStatType.Magic] *
      (1 +
        6 *
          (GameConstant.LevelStatMultiply[MongenStatType.Magic][race] / 100) *
          (MAX_LEVEL + MAX_EVOL - 2)),
    //aggresion
    baseStat[MongenStatType.Aggresion] *
      (1 +
        3 *
          (GameConstant.LevelStatMultiply[MongenStatType.Aggresion][race] /
            100) *
          (MAX_LEVEL + MAX_EVOL - 2)),
  ];
  //calculate power
  let basePower = Math.round(
    (Math.pow(baseStat[0], 0.525) *
      Math.pow(baseStat[1], 0.5) *
      Math.pow(baseStat[2], 0.42) *
      Math.pow(baseStat[3], 0.5)) /
      5
  );
  let currentPower = Math.round(
    (Math.pow(currentStat[0] / 10, 0.525) *
      Math.pow(currentStat[1], 0.5) *
      Math.pow(currentStat[2], 0.42) *
      Math.pow(currentStat[3], 0.5)) /
      10
  );

  let maxPower = Math.round(
    (Math.pow(ultimateMaxStat[0] / 10, 0.525) *
      Math.pow(ultimateMaxStat[1], 0.5) *
      Math.pow(ultimateMaxStat[2], 0.42) *
      Math.pow(ultimateMaxStat[3], 0.5)) /
      10
  );
  let rarity = 0;
  for (var i = GameConstant.RarityPowerRange.length - 1; i >= 0; i--) {
    if (basePower > GameConstant.RarityPowerRange[i]) {
      rarity = i;
      break;
    }
  }
  baseStat = baseStat.map((i: number) => Math.round(i));
  currentStat = currentStat.map((i: number) => Math.round(i));
  ultimateMaxStat = ultimateMaxStat.map((i: number) => Math.round(i));
  return {
    base: baseStat,
    max: currentStat,
    ultimateMax: ultimateMaxStat,
    rarity,
    basePower,
    currentPower,
    maxPower,
  };
}

function shuffle(array: any[]) {
  let currentIndex = array.length,
    randomIndex;

  // While there remain elements to shuffle.
  while (currentIndex !== 0) {
    // Pick a remaining element.
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex],
    ];
  }

  return array;
}

function getDnaName(dna: number[]): string {
  if (dna.length === 1) {
    return Rarity[dna[0]];
  }
  return GameConstant.MongenName[dna[2] - 1][dna[0]][dna[1]];
}

function getDnaRarity(dna: number[]): string {
  if (dna.length === 1) {
    return Rarity[dna[0]];
  }
  return Rarity[dna[1]];
}

function getSkillImage(code: string): string {
  let skillType: SkillType;
  if (code.includes("BS")) {
    skillType = SkillType.Basic;
  } else {
    skillType = SkillType.Ultimate;
  }
  let src = `/assets/images/Skill/${SkillType[skillType]}/${code}.webp`;
  return src;
}

function getTroopImage(code: string): string {
  let src = `/assets/images/Troop/${code}.webp`;
  return src;
}

function formatLongText(text: string = "", length: number) {
  if (text.length <= length) {
    return text;
  }
  let first = Math.ceil(length / 2);
  let remain = length - first;
  return text.slice(0, first) + "..." + text.slice(text.length - remain);
}

const getPlotIcon = (reward: any) => {
  // @ts-ignore
  if (reward.race === -1 || reward.rarity === -1) {
    return null;
  }

  let rarity = reward.rarity as Rarity;
  let src = `/assets/images/plots/${PlotType[
    reward.plot_type
  ].toLowerCase()}-${rarity}.webp`;

  switch (reward.plot_type) {
    case PlotType.Pasture:
      src = `/assets/images/plots/${PlotType[
        reward.plot_type
      ].toLowerCase()}-${MongenRace[reward.race].toLowerCase()}-${rarity}.webp`;
      break;
    case PlotType.Trap:
      if (reward.sub_plot_type) {
        src = `/assets/images/plots/${TrapType[
          reward.sub_plot_type
        ].toLowerCase()}-${PlotType[
          reward.plot_type
        ].toLowerCase()}-${rarity}.webp`;
      }
      break;
    case PlotType.Bombard:
      if (reward.sub_plot_type) {
        src = `/assets/images/plots/${BombardType[
          reward.sub_plot_type
        ].toLowerCase()}-${PlotType[
          reward.plot_type
        ].toLowerCase()}-${rarity}.webp`;
      }
      break;
    case PlotType.Barracks:
      if (reward.sub_plot_type) {
        src = `/assets/images/plots/${BarrackType[
          reward.sub_plot_type
        ].toLowerCase()}-${PlotType[
          reward.plot_type
        ].toLowerCase()}-${rarity}.webp`;
      }
      break;
    case PlotType.Tower:
      if (reward.sub_plot_type) {
        src = `/assets/images/plots/${TowerType[
          reward.sub_plot_type
        ].toLowerCase()}-${PlotType[
          reward.plot_type
        ].toLowerCase()}-${rarity}.webp`;
      }
      break;
    case PlotType.Camp:
      if (reward.sub_plot_type) {
        src = `/assets/images/plots/${CampType[
          reward.sub_plot_type
        ].toLowerCase()}-${PlotType[
          reward.plot_type
        ].toLowerCase()}-${rarity}.webp`;
      }
      break;
  }
  return src;
};

const getSoulcoreIcon = (reward: any) => {
  // @ts-ignore
  if (reward.race === -1 || reward.rarity === -1) {
    return null;
  }
  let src = `/img/reward/egg/${MongenRace[reward.race].toLowerCase()}-${
    reward.rarity
  }.webp`;
  return src;
};

const utils = {
  getSoulcoreIcon,
  getPlotIcon,
  formatLongText,
  getTroopImage,
  getSkillImage,
  getDnaName,
  getDnaRarity,
  shuffle,
  randomInt,
  formatHostUrl,
  getCountdownText,
  getRarityIcon,
  convertToInternationalCurrencySystem,
  getMongenStat,
  getFullMongenStat,
  getCountdownDisplay,
};
export default utils;
