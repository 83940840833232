const GameConstant = {
    BaseFormStat: [
        [41, 35, 31, 39, 35],
        [39, 35, 39, 39, 31],
        [30, 39, 43, 35, 35],
        [30, 31, 27, 27, 39],
    ],

    // [health, spokiness, magic, aggresion] - race
    BaseBodyPartStat: [
        [3, 0, 0, 1, 1],
        [1, 3, 1, 3, 0],
        [0, 0, 0, 0, 0],
        [0, 1, 3, 0, 3],
    ],

    FormRarityBaseMultiply: [
        [1, 1.25, 1.5, 1.75, 2],
        [1, 1.25, 1.5, 1.75, 2],
        [1, 1.25, 1.5, 1.75, 2],
        [1, 1.25, 1.5, 1.75, 2],
    ],
    //  health - sturdiness - magic -aggrestion - rarity
    BodyPartRarityBaseMultiply: [
        [1, 1.25, 1.5, 1.75, 2],
        [1, 1.25, 1.5, 1.75, 2],
        [1, 1.25, 1.5, 1.75, 2],
        [1, 1.25, 1.5, 1.75, 2],
    ],

    //  health - sturdiness - magic -aggrestion - rarity
    LevelStatMultiply: [
        [1.5, 0.6, 0.6, 1.2, 1.2],
        [0.6, 1.2, 1.5, 1.5, 0.6],
        [0.6, 0.6, 0.6, 0.6, 0.6],
        [1.2, 1.5, 1.2, 0.6, 1.5],
    ],

    // common - uncommon - rare - epic - legendary
    RarityPowerRange: [0, 264, 407, 580, 782],
    BreedingLimit: [6, 5, 4, 3, 2],

    // max exp of level
    // [level]
    TotalExpPerLevel: [
        0, 20, 108, 194, 334, 618, 1218, 2218, 3718, 5678, 8478, 12678, 20508,
        36018, 59858, 95508, 132158, 170598, 213398, 267708,
    ],

    MongenName:
        // [level[race[rarity]]]
        // race: beast - tectos - mythic - celest - chaos
        // rarity: common - uncommon - rare - epic - legendary
        [
            [
                ["Cactus Cone", "Tulip Eggo", "Banana Rose", "Cotton Twinke", "-"],
                ["Steam Engine", "Scarecrow Gear", "Strap Clock", "Bulb Metal", "-"],
                [
                    "Vegetable Caterpillar",
                    "Pterois Jelly",
                    "Lighting Seadog",
                    "Water Succulent",
                    "-",
                ],
                [
                    "Lazy Feather",
                    "White Elemental",
                    "Fluppy Light",
                    "Mystical Flame",
                    "-",
                ],
                ["Bat Golem", "Yeti Golem", "King Crab", "Evil Candy", "-"],
            ],
            [
                [
                    "Dragon Poly",
                    "Melon Mutation",
                    "Broccoli Builder",
                    "Pineapple Spirits",
                    "-",
                ],
                [
                    "Plumber Roller",
                    "Candy Bolt",
                    "Electric Belt",
                    "Chemical Pirate",
                    "-",
                ],
                [
                    "Hydra Aquatic",
                    "Flying Emerald",
                    "Darkness Horseshoe",
                    "Bubble Hermit",
                    "-",
                ],
                ["Pinky Cotton", "Star Flame", "Frozen Crystal", "Golden Flame", "-"],
                ["Stone Golem", "Gaunt Bio", "Ravenger Guard", "Darkness Crystal", "-"],
            ],
            [
                ["Dried Pumproom", "-", "-", "-", "-"],
                ["Assassin Worker", "-", "-", "-", "-"],
                ["Deepsea Aquatic", "-", "-", "-", "-"],
                ["Flower Leaf", "-", "-", "-", "-"],
                ["Red Bat", "-", "-", "-", "-"],
            ],
            [
                ["-", "-", "-", "-", "-"],
                ["-", "-", "-", "-", "-"],
                ["-", "-", "-", "-", "-"],
                ["-", "-", "-", "-", "-"],
                ["-", "-", "-", "-", "-"],
            ],
        ],
};
export default GameConstant;
