export interface EnumEntity {
  name: string;
  items: EnumItem[];
  value_type: "string" | "number";
}
export const AllColors = [
  "red",
  "orange",
  "yellow",
  "olive",
  "green",
  "teal",
  "blue",
  "violet",
  "purple",
  "pink",
  "brown",
  "grey",
  "black",
];
export interface FormEntity {
  name: string;
  label: string;
  api: number;
  buttons: Button[];
  controls: FormControl[];
}

export enum Size {
  Small,
  Medium,
  Big,
  Huge,
}
export enum LoginType {
  BSC,
  Terra,
  Username,
  Avalanche,
  Facebook,
  Google,
  GoogleMobile,
  SavedToken,
  Okex,
  Farmer,
}
export enum AuthState {
  Metamask,
  Terra,
  Email,
  Farmer,
}
export enum CustomerRole {
  Owner,
  Farmer,
}
export enum CellDisplay {
  Text = "text",
  Number = "number",
  Date = "date",
  Image = "image",
  Switch = "switch",
  Enum = "enum",
  Entity = "entity",
  JSON = "json",
  Entities = "entities",
  ArrayString = "arraystring",
  File = "file",
}
export interface SearchItem {
  label: string;
  field: string;
  type: "text" | "number" | "date";
  control?: "input" | "enum";
  isRange: boolean;
  enum?: string;
  multiple?: boolean;
}
export interface GridColumn {
  label: string;
  field: string;
  sortField?: string;
  display: CellDisplay;
  api?: string;
  sorter?: boolean;
  defaultSortOrder?: string;
  enumName?: string;
  fixed?: string;
  width: number;
}
export interface Button {
  label: string;
  pageMode?: string;
  classes?: string;
  icon?: string;
  showInColumn?: string;
  action: "api" | "redirect";
  api?: string;
  redirectUrl?: string;
  redirectUrlEmbed?: any;
  embedData?: object;
  popupData?: object;
  confirmText?: string;
  backOnAction?: boolean;
  position?: string;
  successMessage?: string;
  failMessage?: string;
  disableReload?: boolean;
  hideExpression?: object;
}
export enum SchemaDataType {
  String = "string",
  Number = "number",
  Boolean = "boolean",
  Array = "array",
  Object = "object",
}
export enum SchemaControl {
  Enum = "enum",
  Text = "text",
  TextArea = "textarea",
  Image = "image",
  ArrayImage = "arrayimage",
  Location = "location",
  RichText = "richtext",
  Password = "password",
  Captcha = "captcha",
  Label = "label",
  Step = "step",
  Entity = "entity",
  JSON = "json",
  Date = "date",
  TextArray = "textarray",
  Divider = "divider",
  CheckList = "checklist",
  Schema = "schema",
  Teacher = "teacher",
  Upload = "upload",
}

export interface FormControl {
  label: string;
  field: string;
  dataType: SchemaDataType;
  caption?: string;
  required: boolean;
  disabled: boolean;
  control: SchemaControl;
  gridName: string;
  schemaName: string;
  enum: string;
  hideExpression?: object;
  requireExpression?: object;
  api: string;
  multiple?: boolean;
}
export enum Language {
  English,
  Chinese,
}
export interface EnumItem {
  value: string;
  label: string;
  classes?: string;
}
export interface GridEntity {
  name: string;
  label: string;
  api: string;
  buttons: Button[];
  columns: GridColumn[];
  filter: SearchItem[];
}
export enum YesNoEnum {
  No,
  Yes,
}
export interface UserEntity {
  id: number;
  name: string;
  role: UserRole;
}
export interface AuthEntity {
  A: number; // "id",
  B: number; // "customer_id",
  C: string; // "identity",
  D: number; // "type",
  E: number; // "role",
  F: string; // "password",
  G: boolean; // "is2FARequired",
  H: string; // password_last_update
  I: boolean; // is_2fa_enabled
  mstr_wallet: number;
  mag_wallet: number;
}

export interface CustomerTransaction {
  A: number; // id,
  B: number; // customer_i,d,
  C: Token; // token_type,
  D: number; // value
  E: Date; // claim_at
  F: boolean; // is_claim
  G: string; //wallet address
  H: Date; // create_at
}

export enum ButtonColor {
  "red",
  "orange",
  "blue",
  "yellow",
  "gray",
  "purple",
  "brown",
}

export enum ButtonVariant {
  "primary",
  "outline",
}

export enum GameItem {
  "mag",
  "mstr",
  "evo-item",
}

export enum RewardType {
  FOOD,
  EVOLUTION_ITEM,
  LOCK_MAG,
  EXP,
  SOULCORE,
  MSTR,
  PLOT,
  BOX,
  TICKET,
  NormalStone,
  RerollStone,
  EliteStone,
  NormalRuneStone,
  RerollRuneStone,
  EliteRuneStone,
  TROPHY_SCORE,
  BattleFrontShield,
  Energy,
  Avatar,
  DecorationList,
  Pigment,
  DailyQuestPoint,
  UNLOCK_MAG,
}
export interface RewardEntity {
  reward_type: RewardType;
  reward_quantity: number;
  plot_type: PlotType;
  race: MongenRace;
  rarity: Rarity;
  rate: number;
  decoration_code: string;
  box_name: string;
  sub_plot_type: number;
}

export enum PlotType {
  LandCore,
  Pasture,
  Breeding,
  Hatching,
  Production,
  Storage,
  Camp,
  Tower,
  Bombard,
  Trap,
  Barracks,
  Decor,
  All,
}

export enum Token {
  MAG,
  MSTR,
}

export enum Rarity {
  Common,
  Uncommon,
  Rare,
  Epic,
  Legendary,
  All,
}
export enum MongenRace {
  Beast,
  Tectos,
  Mystic,
  Celest,
  Chaos,
  All,
}
export interface GasPrice {
  denom: string;
  price: number;
}
export enum TicketRarity {
  Common,
  Uncommon,
  Rare,
  Epic,
  Legendary,
  General,
  Global
}
export enum NFTType {
  Mongen,
  SoulCore,
  Land,
  Plot,
  EvolutionItem,
  NormalStone,
  RerollStone,
  EliteStone,
  NormalRuneStone,
  RerollRuneStone,
  EliteRuneStone,
  TicketCO,
  TicketUC,
  TicketRA,
  TicketEP,
  TicketLE,
}

export enum Chain {
  BSC,
  Terra,
}
export enum TicketSubject {
  Account,
  Gameplay,
  Marketplace,
  Bridge,
  DAO,
  Guild,
  PortalGame,
  Others,
}
export enum TicketStatus {
  Unresolved,
  Resolved,
}
export enum TicketPriority {
  Low,
  Medium,
  High,
  Highest,
}
export interface TicketComment {
  name: string;
  content: string;
  attachs: string[];
  createdDate: Date;
  creator_id: number;
  is_admin: boolean;
}
export enum UserRole {
  Customer,
  Admin,
}
export interface UserInfo {
  id: number;
  name: string;
  role: UserRole;
}

export enum EventProperty {
  StakeMSTRMilestone,
  StakeMSTRTop,
  SuperFarmer,
  GodOfWar,
  Noblesse,
  PowerBreaker,
  InvincibleStronghold,
  MongenCreatorCo,
  MongenCreatorUc,
  MongenCreatorRa,
  MongenCreatorEp,
  MongenCreatorLe,
  DailyLogin,
  MSTRSpending,
  MAGSpending,
  TradingCampCo,
  TradingCampUc,
  TradingCampRa,
  TradingCampEp,
  MSTRSpendingTop,
  MAGSpendingTop,
  CampDestroyer,
  TowerDestroyer,
  TurretDestroyer,
  TopConquerorBattlefront,
  DamageBossChallenge,
  Referral,
  ReachLevel,
  TopEXP,
  LootFood,
  LootSMAG,
  TradingRank,
  DailyTrading,
  PortalGame,
  OkexEventLevel,
  OkexEventReferral,
}

export enum RewardOpenType {
  All,
  Random,
}
export interface EventMilestone {
  name: string;
  require: number;
  open_type: RewardOpenType;
  rewards: RewardEntity[];
}
export enum GamePlatform {
  All,
  Android,
  iOS,
  Web,
  Window,
  MacOS,
  Linux,
}
export enum EventClaimType {
  During, //calculate during progress once
  Final, //calculate the final milestone
  Rank, //calculate in rank then milestone
  DuringRepeat, //calculate during progress multiple
}
export interface EventEntity {
  id: number;
  name: string;
  property: EventProperty;
  is_champion_ship: boolean;
  milestones: EventMilestone[];
  claim_type: EventClaimType;
  is_active: boolean;
  start_time: Date;
  end_time: Date;
}
export enum FeatureMapping {
  None,
  DAOHome,
  GameHome,
  LeaderBoardHome,
  LeaderBoardChampionship,
  MarketPlace,
}
export interface EventGroupEntity {
  id: number;
  avatar: string;
  name: string;
  rule: string;
  is_champion_ship: boolean;
  start_time: Date;
  end_time: Date;
  platforms: GamePlatform[];
  eventInfos: EventEntity[];
  feature_mapping: FeatureMapping;
  feature_mapping_url: string;
  is_active: boolean;
}

export interface EventPropertyValue {
  customer_id: number;
  property: number;
  event_id: number;
  value: number;
  claimables: number[];
  meta: any;
  averange: any;
}

export interface CustomerClaimEventLogEntity {
  customer_id: number;
  event_id: number;
  claimed: number[];
}

export type SSO_TYPE = {
  DAOLink: string;
  GuideLink: string;
  DaoGuildLink: string;
  LiteLink: string;
  GameLink: string;
  SupportLink: string;
  WhitePaperLink: string;
  TerraChainId: string;
  BattleLink: string;
  PortalLink: string;
  BridgeLink: string;
  GuildLink: string;
  EventLink: string;
  MarketLink: string;
  DocsLink: string;
  MonstvLink: string;
  MonsterraverseLink: string;
  LandingPage: string;
  MetamaskExtension: string;
  TerraExtension: string;
  contract: any;
  network: any;
  GOOGLE_CLIENT_ID: string;
  FACEBOOK_APP_ID: string;
  CAPTCHA_CLIENT_ID: string;
  S3Host: string;
  price: {
    BNB: string;
    AVAX: string;
    LUNA: string;
    UST: string;
    OKT: string;
  };
};

export enum QueueState {
  Pending,
  Rolled,
  Cancel,
}

export enum SocketAction {
  Authen,
  GetActiveEvent,
  GetListProducts,
  JoinQueue,
  Spin,
  ResetEvent,
  Cancel
}
export enum SocketNotify {
  EventOpen,
  EventStart,
  OpenConfirm,
  SpinResult,
  EventFinish,
}
export enum ProductType {
  Mongen,
}

export enum EventState {
  Pending,
  Open,
  Start,
  Finish,
}

export enum CustomerInterface {
  id = "A",
  in_battle = "B",
  user_name = "C",
  name = "D",
  avatar = "E",
  progress = "F",
  food = "G",
  mag = "H",
  mstr = "I",
  evolution_items = "J",
  energy = "K",
  nm_st = "L",
  rr_st = "M",
  el_st = "N",
  nmr_st = "O",
  rrr_st = "P",
  elr_st = "Q",
  active_land_id = "R",
  exp = "S",
  block_until = "T",
  level = "U",
  level_reward = "V",
  fame = "W",
  referral = "X",
  referral_last_submit = "Y",
  ticket_co = "Z",
  ticket_uc = "AA",
  ticket_ra = "AB",
  ticket_ep = "AC",
  ticket_le = "AD",
  created_at = "AE",
  referred_claimed_rewards = "AF",
  adventure_star_reward = "AG",
  adventure_star = "AH",
  count_refill_energy = "AI",
  staked_mag = "AJ",
  earn_mag = "AK",
  collected_plots = "AL",
  souldcore_hatched = "AM",
  breeding_count = "AN",
  scholar_owner = "AO",
  scholar_permissions = "AP",
  scholar_address = "AQ",
  ticket_ge = "AR",
  ticket_gl = "AS",
  level_reward_info = "AT",
  earn_ft = "AU",
  avatar_pool = "AV",
  sharing_rate = "AW",
  expect_earning = "AX",
  scholar_id = "AY",
  max_capacity = "AZ",
  scholar_expired_at = "BA",
  energy_event = "BB",
  count_buy_energy_event = "BC",
  battlefront_mongen_team = "BD",
  boss_mongen_team = "BE",
  bonus_tuts = "BF",
  pigment = "BG",
  action_points = "BH",
  curse_effect_receive = "BI",
}

export enum MongenInterface {
  id = "A",
  name = "B",
  dna = "C",
  customer_id = "D",
  plot_id = "E",
  is_attach = "F",
  level = "G",
  next_level_progress = "H",
  mongen_state = "I",
  evolution = "J",
  is_lock = "K",
  is_free = "L",
  evolution_points = "M",
  land_id = "N",
  births = "O",
  basic_skill = "P",
  ultimate_skill = "Q",
  basic_skill_list = "R",
  ultimate_skill_list = "S",
  is_tut = "T",
  troop_list = "U",
  training_queue = "V",
  freeze_until = "W",
  strategy = "X",
  is_deassigning = "Y",
  mongen_battle = "Z",
  battle_count = "AA",
  acc_battle = "AB",
  win_count = "AC",
  lose_count = "AD",
  fed_food = "AE",
  fed_evolve = "AF",
  fed_stones = "AG",
  loyalty = "AH",
  morale = "AI",
  morale_eff_value = "AJ",
}

export enum PlotInterface {
  id = "A",
  name = "B",
  customer_id = "C",
  plot_type = "D",
  x = "E",
  y = "F",
  size_x = "G",
  size_y = "H",
  plot_race = "I",
  mongen_race = "J",
  rarity = "K",
  level = "L",
  breeding_time = "M",
  hatching_time = "N",
  produce_time = "O",
  upgrade_time = "P",
  production_mode = "Q",
  is_attach = "R",
  attach_mongens = "S",
  is_lock = "T",
  is_free = "U",
  land_id = "V",
  is_land_active = "W",
  training_time = "X",
  freeze_until = "Y",
  decoration_list = "AB",
  sub_plot_type = "AD",
}

export enum MongenState {
  Egg,
  Idle,
  Breeding,
  Working,
  Traning,
  Battle,
}


export enum BodyPart {
  Form,
  Head,
  Eyes,
  Horns,
  Tail,
  Back,
  FrontLeg,
  BackLeg,
  Mouth,
  Aura,
}

export enum MongenStatType {
  Health,
  Sturdiness,
  Magic,
  Aggresion,
}

export enum SkillType {
  Basic,
  Ultimate,
}

export class Skill {
  id: number;
  code: string;
  name: string;
  rarity: number; //rarity
  dmg: number; //damage
  ed: number; //effect damage
  edu: number; //effect damage unit
  eng: number; //energy
  ct: number; //cast time
  cd: number; //cooldown
  trg: Target; //target
  aoe: Aoe;
  eff: EffectType; //effect
  er: number; //effect rate
  et: number; //effect time
  speed: number; //speed
  rng: number; //range
  turn_loop: number;
  weight: number;
  type: SkillType; //type
  alive: boolean;
  target_group: number;
  unit: number;
  is_elite: boolean;
  description: string;
  is_projectile: boolean;
  weight_celest: number;
  weight_chaos: number;
  weight_boss: number;
}
export enum Target {
  LandPlot,
  Mongen,
  Troop,
  Any,
}
export enum Aoe {
  Single,
  Square,
  Ray,
  Link,
}
export enum EffectType {
  KnockBack,
  Burned,
  Poisoned,
  Paralyzed,
  Confused,
  Sleepy,
  Slow,
  Heal,
  Shield,
  Revive,
  Debuff,
  Magic,
  Sturdiness,
  Aggressive,
  LockHealEffect,
  LockEnergyEffect,
  Die,
  EnergyVamp,
  Charging,
  Regen,
}

export enum AssetType {
  Mongen,
  Plot,
  MAG,
  MSTR,
  Soulcore,
}

export enum EventResult {
  Pending,
  Win,
  Lose,
  Refunded
}

export enum TrapType {
  Default,
  Poison,
  Slowed,
  Paralyzed,
  Confused,
  Bleeding,
  Silence,
  Burned,
  MG,
  STD,
  AGR,
}

export enum BombardType {
  Default,
  RNG,
  ENG,
}

export enum BarrackType {
  Default,
  Food,
}

export enum TowerType {
  Default,
  RNG,
  CD,
}

export enum CampType {
  Default,
  HP,
  MG,
  STD,
  AGR,
}

export enum MatchEventState {
  Pending,
  Reject,
  Approved,
}