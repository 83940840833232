import { LoginType, SSO_TYPE } from "interfaces";
import { store } from "reducer/store";

export const SSO_META = () => {
  let SSO_META: SSO_TYPE = store.getState().ssometa.meta;
  return SSO_META;
}

export const SSO_CONTRACT = () => store.getState().ssometa.contract;

const Config = {
  ApiHost: process.env.REACT_APP_API_HOST,
  SupportedAuth: [LoginType.BSC, LoginType.Terra, LoginType.Avalanche, LoginType.Okex, LoginType.Username, LoginType.Facebook, LoginType.Google],
  AntiWalletAuth: [LoginType.Username, LoginType.Facebook, LoginType.Google],

  FormattedTime: {
    // weekday: "long",
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  },
};
export default Config;