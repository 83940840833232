import {
  BarrackType,
  BombardType,
  CampType,
  MongenRace,
  PlotType,
  RewardEntity,
  RewardType,
  TicketRarity,
  TowerType,
  TrapType,
} from "interfaces";
import { utils } from "services";

const getRewardIcon = (reward: RewardEntity) => {
  switch (reward.reward_type) {
    case RewardType.LOCK_MAG:
      return "s-mag.png";
    case RewardType.UNLOCK_MAG:
      return "mag.png";
    case RewardType.DailyQuestPoint:
      return 'quest-point.webp';
    case RewardType.MSTR:
      return "mstr.png";
    case RewardType.SOULCORE:
      //@ts-ignore
      if (reward.race === -1) {
        return "soulcore-icon.svg";
      } else {
        return `egg/${MongenRace[reward.race]?.toLowerCase()}-${reward.rarity}.webp`
      }
    case RewardType.PLOT:
      switch (reward.plot_type) {
        // @ts-ignore
        case -1:
          return "plot-random.png";
        case 1:
          if (!MongenRace[reward.race]) {
            return "plot-2-icon.svg"
          }
          return `plots/${PlotType[
            reward.plot_type
          ].toLowerCase()}-${MongenRace[reward.race]?.toLowerCase()}-${reward.rarity
            }.webp`;
        case PlotType.Tower:
          if (reward.sub_plot_type) {
            return `plots/${TowerType[
              reward.sub_plot_type
            ].toLowerCase()}-${PlotType[reward.plot_type].toLowerCase()}-${reward.rarity
              }.webp`;
          } else {
            return `plots/${PlotType[reward.plot_type].toLowerCase()}-${reward.rarity
              }.webp`;
          }
        case PlotType.Trap:
          if (reward.sub_plot_type) {
            return `plots/${TrapType[
              reward.sub_plot_type
            ].toLowerCase()}-${PlotType[reward.plot_type].toLowerCase()}-${reward.rarity
              }.webp`;
          } else {
            return `plots/${PlotType[reward.plot_type].toLowerCase()}-${reward.rarity
              }.webp`;
          }
        case PlotType.Bombard:
          if (reward.sub_plot_type) {
            return `plots/${BombardType[
              reward.sub_plot_type
            ].toLowerCase()}-${PlotType[reward.plot_type].toLowerCase()}-${reward.rarity
              }.webp`;
          } else {
            return `plots/${PlotType[reward.plot_type].toLowerCase()}-${reward.rarity
              }.webp`;
          }
        case PlotType.Barracks:
          if (reward.sub_plot_type) {
            return `plots/${BarrackType[
              reward.sub_plot_type
            ].toLowerCase()}-${PlotType[reward.plot_type].toLowerCase()}-${reward.rarity
              }.webp`;
          } else {
            return `plots/${PlotType[reward.plot_type].toLowerCase()}-${reward.rarity
              }.webp`;
          }
        case PlotType.Camp:
          if (reward.sub_plot_type) {
            return `plots/${CampType[
              reward.sub_plot_type
            ].toLowerCase()}-${PlotType[reward.plot_type].toLowerCase()}-${reward.rarity
              }.webp`;
          } else {
            return `plots/${PlotType[reward.plot_type].toLowerCase()}-${reward.rarity
              }.webp`;
          }
        default:
          return `plots/${PlotType[reward.plot_type].toLowerCase()}-${reward.rarity
            }.webp`;
      }
    case RewardType.EVOLUTION_ITEM:
      return "evo-item-icon.svg";
    case RewardType.RerollRuneStone:
      return "rerolled-rune.png";
    case RewardType.NormalRuneStone:
      return "normal-rune.png";
    case RewardType.NormalStone:
      return "normal-stone.png";
    case RewardType.RerollStone:
      return "rerolled-stone.png";
    case RewardType.FOOD:
      return "food.png";
    case RewardType.EXP:
      return "exp.png";
    case RewardType.TICKET:
      switch (reward.rarity as number) {
        case TicketRarity.Common:
          return "ticket-0.png";
        case TicketRarity.Uncommon:
          return "ticket-1.png";
        case TicketRarity.Rare:
          return "ticket-2.png";
        case TicketRarity.Epic:
          return "ticket-3.png";
        case TicketRarity.Legendary:
          return "ticket-4.png";
        case TicketRarity.General:
          return 'ticket-5.png';
        case TicketRarity.Global:
          return 'ticket-6.png';
        default:
          return "question.webp";
      }
    case RewardType.TROPHY_SCORE:
      return "trophy.png";
    case RewardType.BattleFrontShield:
      return "shield.png";
    case RewardType.Energy:
      return "energy.png";
    case RewardType.DecorationList:
      return `deco/${reward.decoration_code}.webp`
    case RewardType.Pigment:
      return "pigment.png";
    default:
      return "question.webp";
  }
};

export default function ListReward({
  rewards,
  showPercent,
}: {
  rewards: RewardEntity[];
  showPercent?: boolean;
}) {
  const rarityColors = ["#BDC3E4", "#4DA74F", "#63A7D3", "#C052EF", "#F89E2A"];
  function getBgColor(r: RewardEntity) {
    switch (r.reward_type) {
      case RewardType.Pigment:
        return "#f606ed";
      case RewardType.BOX:
        return "#bab221";
      case RewardType.LOCK_MAG:
      case RewardType.MSTR:
      case RewardType.FOOD:
      case RewardType.EVOLUTION_ITEM:
      case RewardType.EXP:
      case RewardType.NormalStone:
      case RewardType.RerollStone:
      case RewardType.EliteStone:
      case RewardType.NormalRuneStone:
      case RewardType.RerollRuneStone:
      case RewardType.EliteRuneStone:
      case RewardType.BattleFrontShield:
      case RewardType.Energy:
      case RewardType.Avatar:
      case RewardType.DecorationList:
      case RewardType.DailyQuestPoint:
      case RewardType.UNLOCK_MAG:
        return "#bcc3e5"
      default:
        return rarityColors[r.rarity];
    }
  }
  function renderRandomPercent(r: RewardEntity) {
    if (!showPercent) {
      return;
    }
    let total = rewards.reduce((a: number, b: RewardEntity) => {
      return a + b.rate;
    }, 0);
    return (
      <p className="text-center">
        {Number(((r.rate / total) * 100).toFixed(2))}%
      </p>
    );
  }
  function renderRewardIcon(r: RewardEntity) {
    switch (r.reward_type) {
      case RewardType.Pigment:
      case RewardType.BOX:
      case RewardType.LOCK_MAG:
      case RewardType.MSTR:
      case RewardType.FOOD:
      case RewardType.EVOLUTION_ITEM:
      case RewardType.EXP:
      case RewardType.NormalStone:
      case RewardType.RerollStone:
      case RewardType.EliteStone:
      case RewardType.NormalRuneStone:
      case RewardType.RerollRuneStone:
      case RewardType.EliteRuneStone:
      case RewardType.BattleFrontShield:
      case RewardType.Energy:
      case RewardType.Avatar:
      case RewardType.DecorationList:
      case RewardType.DailyQuestPoint:
      case RewardType.UNLOCK_MAG:
        return null;
      default:
        return (
          <>
            {utils.getRarityIcon(r.rarity) && (
              <img
                src={utils.getRarityIcon(r.rarity)}
                alt="rarity"
                className="w-7 h-7 absolute -top-2 -left-2.5"
              />
            )}
          </>
        );
    }
  }
  return (
    <div className="flex gap-2 justify-center flex-wrap">
      {rewards?.map((r: RewardEntity, idx) => (
        <div key={`reward-${idx}`}>
          <div
            className="border-2 border-solid rounded-md p-4 relative  h-20 w-20 flex items-center justify-center"
            style={{ borderColor: getBgColor(r) }}
          >
            <div
              className="h-10 w-10 blur-md"
              style={{ backgroundColor: getBgColor(r) }}
            ></div>
            <div></div>
            {r?.reward_type !== null && (
              <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center">
                <img
                  alt="reward"
                  src={`img/reward/${getRewardIcon(r)}`}
                  className="h-16 w-16 object-contain"
                />
                <p className="absolute bottom-0 w-full text-right left-0 pr-2 text-white">
                  {r.reward_quantity}
                </p>
                {renderRewardIcon(r)}
              </div>
            )}
          </div>
          {renderRandomPercent(r)}
        </div>
      ))}
    </div>
  );
}
