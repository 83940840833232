
export default function RankItem({ data }: { data: any }) {
  return (
    <div className="w-full p-1">
      <div className="bg-[#212025] py-4 px-6 flex gap-4 justify-between rounded-sm items-center">
        <div className="flex gap-10 items-center">
          <p className="text-2xl text-white">#{data.rank}</p>
          <div>
            <p className="text-xl text-white text-ellipsis whitespace-nowrap w-44 overflow-hidden mx-auto">
              {data.name}
            </p>
            <p className="text-[#BBBDC6] mt-2">{data.identity}</p>
          </div>
        </div>
        <p className="text-xl text-[#FFCF78] font-semibold">
          {Number(data.score.toFixed(2))}
        </p>
      </div>
    </div>
  );
}
