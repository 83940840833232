import { createSlice } from "@reduxjs/toolkit";
import { UserEntity } from "interfaces";
const initialState: UserEntity = {
  id: 0,
  name: "",
  role: 0,
};
const todosSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    clearUserInfo() {
      return null;
    },
    setUserInfo(state: any, action) {
      let tmp = { ...(state || {}) };
      for (var i in action.payload) {
        tmp[i] = action.payload[i];
      }
      return tmp;
    },
    bulkSetUserInfo(state: any, action: any) {
      let tmp: any = { ...state, ...initialState };
      for (var i in action.payload) {
        tmp[i] = action.payload[i];
      }
      return tmp;
    },
  },
});

export const { setUserInfo, clearUserInfo, bulkSetUserInfo } =
  todosSlice.actions;

export default todosSlice.reducer;
