import Config from "config";
import { clearSSOMeta, setSSOMeta } from "reducer/ssometa.slice";
import { store } from "reducer/store";
import { clearUserInfo } from "reducer/user.slice";
import { clearAuthInfos } from "reducer/wallet.slice";
import ui from "./ui";
import utils from "./utils";

// config api method
async function post(url: string, data: any): Promise<any> {
  let rs = await fetch(`${utils.formatHostUrl(Config.ApiHost)}${url}`, {
    method: "POST",
    mode: "cors",
    headers: {
      "Content-Type": "application/json; charset=UTF-8",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    body: JSON.stringify(data),
  });
  switch (rs.status) {
    case 200:
      let tmp = await rs.json();
      return tmp;
    case 403:
      sessionStorage.clear();
      localStorage.clear();
      store.dispatch(clearUserInfo());
      store.dispatch(clearAuthInfos());
      ui.alertFailed("Your session is expired !", "Please sign in again !");
      throw new Error("forbidden");
    default:
      let err = await rs.json();
      throw err;
  }
}

async function get(url: string): Promise<any> {
  let rs = await fetch(`${utils.formatHostUrl(Config.ApiHost)}${url}`, {
    method: "GET",
    mode: "cors",
    headers: {
      "Content-Type": "application/json; charset=UTF-8",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
  switch (rs.status) {
    case 200:
      let tmp = await rs.json();
      return tmp;
    case 403:
      sessionStorage.clear();
      localStorage.clear();
      throw new Error("forbidden");
    default:
      let err = await rs.json();
      throw err;
  }
}

async function getSSOMeta() {
  const ssoMeta = await get("/customer/get-SSO-meta");
  store.dispatch(clearSSOMeta());
  store.dispatch(setSSOMeta(ssoMeta));
}

// export
const api = {
  post,
  get,
  getSSOMeta,
};

export default api;
