import { Menu } from "semantic-ui-react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { PageComponent } from "components/page-component/page-component";
import { SSO_META } from "config"

export default function Header() {
  const nav = useNavigate();
  return (
    <div className="w-full main-header bg-black-primary" style={{ zIndex: "99" }}>
      <div className="hidden lg:block">
        <Menu inverted className="main-container" borderless>
          <Menu.Item
            onClick={() => {
              nav("/");
            }}
            header
            className="logo-header"
          >
            <img src="/assets/images/logo.png" alt="logo" />
          </Menu.Item>
          <PageComponent SSO_META={SSO_META()} />
        </Menu>
      </div>

      <div className="lg:hidden w-full">
        <Menu inverted={true} borderless className="header-mb">
          <Menu.Item onClick={() => { nav('/') }} header className="logo-header" key="mb-logo">
            <img src="/assets/images/logo.png" alt="logo" />
          </Menu.Item>
          <PageComponent SSO_META={SSO_META()} />
        </Menu>
      </div>
    </div >
  );
}
