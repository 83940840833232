import { Routes, Route } from "react-router-dom";
import ScrollToTop from "./scrollToTop";
import ChampionShip from "championship";

export default function Main() {
  return (
    <div>
      <div className={``}>
        <ScrollToTop>
          <Routes>
            <Route path="/" element={<ChampionShip />} />
          </Routes>
        </ScrollToTop>
      </div>
    </div>
  );
}
